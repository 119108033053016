import styled from "styled-components"

export const Container = styled.section` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 980px;
    padding: 0 40px;
    margin-top: 40px;

    h2 {
        font-size: 20px;
        font-weight: normal;
        font-family: 'Open Sans', sans-serif;
        color: black;
        text-align: center;
        width: 100%;
    }

    .activate-your-brand {
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: #F5F5F5;
        border-left: 20px solid;
        border-color: #009FE3;
        padding: 25px 60px 50px 40px;

        @media screen and (max-width: 990px) {
            padding: 25px 12px 50px 20px;
        }

        p {
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
        }

        span {
            font-size: 16px;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
        }

        &__content {
            margin-top: 5px;
            display: flex;
            align-items: flex-end;
            gap: 40px;

            @media screen and (max-width: 990px) {
                flex-direction: column;
            }

            p {
                font-weight: bold;
                margin: 0;
            }

            span {
                font-weight: normal;
                font-size: 16px;

                &.email {
                    text-decoration: underline;
                }
            }
        }

        &__contact {
            border-left: 2px solid;
            border-color: #009FE3;
            padding-left: 15px;
        }
    }
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`

export const Top = styled.div` 
  position: relative;
  min-height: 200px;
  width: 100%;
  background-image: url(${props => props.BgImage});
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  h1 {
    color: #fff;
    font-size: 2.25rem;
    font-weight: 700;
  }


`