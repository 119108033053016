import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height:520px;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  background-color: var(--white-smoke);

  @media (max-width: 990px) {
    height: 540px;
  }

  .container-icon {
    display: flex;
    align-items: flex-start;
    background-color: var(--white-smoke);
  }

  h1 {
    color: #ef7d00;
    padding-top: 48px;
    padding-bottom: 68px;

    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    font-family: "Open Sans";

    @media (max-width: 990px) {
      font-size: 28px;
      line-height: 40px;
    }
  }

  h2 {
    color: #ef7d00;
    padding-bottom: 28px;
    text-transform: uppercase;
    margin-top: 30px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    font-family: "Open Sans";

    @media (max-width: 990px) {
      font-size: 28px;
      line-height: 40px;
    }
  }

  .text {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    font-family: "Open Sans";

    @media (max-width: 990px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 798px;
    height: 180px;

    @media (max-width: 990px) {
      width: 320px;
      height: 308px;
    }
  }
`;

export const Stack = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 990px) {
    flex-direction: column;
  }

  .container-button {
    width: auto;
    display: flex;
    justify-content: center;

    @media (max-width: 990px) {
      width: 100%;
    }

    a {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      height: 48px;
      min-width: 222px;
      padding: 0 20px;
      font-style: normal;
      font-weight: 600;
      font-family: "Open Sans";
      font-size: 23px;
      text-transform: uppercase;
      text-align: center;
      line-height: 64px;
      transition: all 0.5s ease 0s;
      background-color: transparent;
      border: 2px solid var(--orange);
      color: var(--orange);

      @media (max-width: 990px) {
        width: 100%;
        font-size: 14px;
      }

      &:hover {
        background-color: var(--orange);
        color: var(--white);
      }
    }
  }
`;
