/* Slideshow */

import styled from "styled-components";

export const ShowSlide = styled.section`
  position: relative;
  margin: 0 auto;
  overflow: hidden;

  .slideshowSlider {
    display: flex;
    transition: ease 1000ms;
  }

  .slide {
    background-color: #c1c1c1;
    position: relative;

    height: 714px;
    width: 100%;
    min-width: 100%;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      margin: 0 auto;
      padding: 0 2rem;

      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;

      .content {
        width: 702px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .inner_content {
          @media (max-width: 990px) {
            width: 223px;
          }

          a {
            background-color: #ef7d00;
            border: 2px solid #ef7d00;
          }
        }

        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 53px;
          line-height: 64px;
          font-family: "Open Sans";

          color: #fff;
          margin-bottom: 1rem;

          @media (max-width: 990px) {
            width: 344px;
            font-size: 26px;
            line-height: 35px;
          }
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 134px;

          p {
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            line-height: 35px;
            font-family: "Open Sans";
            color: #fff;
            text-align: center !important;

            @media (max-width: 990px) {
              font-size: 16px;
              line-height: 20px;
            }
          }

          strong {
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 35px;
            font-family: "Open Sans";

            @media (max-width: 990px) {
              font-size: 16px;
              line-height: 20px;
            }
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 48px;
            min-width: 222px;

            font-style: normal;
            font-weight: 600;
            font-family: "Open Sans";

            font-size: 24px;
            text-transform: uppercase;
            text-align: center;
            line-height: 64px;

            transition: 0.5s;

            background-color: var(--orange);
            border: 2px solid var(--orange);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
            color: #ffffff;
            padding: 0.8rem;

            @media (max-width: 990px) {
              font-size: 18px;
              line-height: 20px;

              min-width: 166px;
              min-height: 44px;
            }
          }
        }
      }
    }
  }
  
  /* triangule borders */

  .triangle-right {
    position: absolute;
    right: 0;
    top: 0;
    border-style: solid;
    border-width: 0px 180px 100px 0px;
    border-color: transparent #ef7d00 transparent transparent;
    z-index: 900;

    @media (max-width: 990px) {
      border-width: 0px 130px 80px 0px;
    }
  }

  .triangle-left {
    position: absolute;
    left: 0;
    bottom: 0;
    border-style: solid;
    border-width: 0px 300px 150px 0px;
    border-color: #ef7d00 transparent #ef7d00 transparent;
    z-index: 900;

    @media (max-width: 990px) {
      border-width: 0px 140px 80px 0px;
    }
  }

  /* Buttons */

  .slideshowDots {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 1366px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 2rem;
  }

  .slideshowDot {
    cursor: pointer;
    display: inline-block;
    height: 8px;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 14px;
    margin-right: 5px;
    transition: all 0.3s ease;

    &:hover,
    &.active {
      background-color: var(--orange);
    }
  }

  @media screen and (max-width: 990px) {
    .slide {
      height: 563px;
    }
  }
`;
