import styled from "styled-components";

export const ContainerTable = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 50px 0;


  h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 1.875rem;
    line-height: 2.1875rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
  }

  div {
    max-width: 100%;
    overflow: hidden;
    overflow-x: hidden;
    overflow-x: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  table {
    border-collapse: collapse;
  }

  thead {
    background-color: #f1f1f1;
  }
  tr,
  th {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    padding: 15px 40px;
    border: 1px solid #cfcfcf;
    border-bottom: none;
    text-align: center;
  }

  span {
    padding: 3px 15px;
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 0.625rem;
    margin: 0;
    margin-top: 10px;
  }

  tbody,
  tr,
  td {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    padding: 8px 15px;
    border: 1px solid #cfcfcf;
    text-align: center;
  }
  tbody,
  tr,
  td:first-of-type {
    text-align: left;
    font-weight: 700;
  }

  .container-button-table {
    margin: 2rem auto;
    max-width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  button > svg {
    margin-right: 10px;
  }

  button > svg > g > path {
    stroke: var(--orange);
    transition: all 0.5s;
  }
  button:hover > svg > g > path {
    stroke: #000000;
  }
  .btn-trasparent {
    width: 10rem;
    height: 3rem;
    background-color: #009FE3;
    border: solid 1px #009FE3;
    color: black;
    position: relative;
    z-index: 1;
    transition: 0.5s;
    overflow: hidden;
  }
  .btn-trasparent:hover {
    background-color: #ffffff;
    color: #009FE3;
    border: 2px #009FE3 solid;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .btn-trasparent:hover {
    background-color: #ffffff;
    color: #009FE3;
    border: 2px #009FE3 solid;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .btn-effect {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 20rem;
    height: 3rem;
    background-color: white;
    border: solid 1px #009FE3;
    color: #009FE3;
    position: relative;
    z-index: 1;
    transition: 0.5s;
    overflow: hidden;
  }

  .btn-effect:hover {
    background-color: #009FE3;
    color: black;
    border: 2px #009FE3 solid;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1024px) {
    scroll-margin-top: 5rem;
  }
`;
